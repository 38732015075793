import { useLocation } from 'react-router-dom';

import { SideNav as BaseSideNav } from '@/components';
import { adminToolPages } from '@/modules/AdminTools/routing';
import { PATH as promoCodePath } from '@/modules/AdminTools/routing/promo-code';
import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';

const SideNav = () => {
  const location = useLocation();

  const isCurrentLocation = (path) => {
    path = path.replace(/^\/|\/$/g, '');
    const pathParts = location.pathname.replace(/^\/|\/$/g, '').split('/');

    return pathParts[0] === path;
  };

  const { value: isPromotionBuilderEnabled } = useFeatureFlag(
    dashboardConstants.PROMOTION_BUILDER_FEATURE_FLAG_NAME,
    false,
  );

  const items = adminToolPages.map(({ NAME, PATH }) => ({
    name: NAME,
    path: PATH,
    active: isCurrentLocation(PATH),
  })).filter(({ path }) => isPromotionBuilderEnabled || path !== promoCodePath);

  return <BaseSideNav items={items} />;
};

export default SideNav;
